<template>
  <div>
    <div class="content-wrapper nps">
      <header-title
        title="Nps"
        iconTitle="nav-icon fa fa-star font-color-custom"
      />

      <search-nps
        :qtdNps="qtdNps"
      />
      <hr class="mt-2" />
      <list-nps @qtd_nps="qtdNps = $event" />
    </div>
  </div>
</template>

<script>
export default {
  components: {
    "header-title": () =>
      import("../cs/notifications/components/HeaderTitle.vue"),
    "search-nps": () => import("./components/searchNps.vue"),
    "list-nps": () => import("./components/listNps.vue"),
  },
  data() {
    return {
      qtdNps: 0,
    };
  },
};
</script>

<style scoped>
.nps {
  background-color: #fff;
}
</style>